<template>
  <section id="contact-form" class="section height-625 has-background-dark">
    <section class="columns is-multiline is-centered">
      <section class="column is-8">
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <form
            class="has-background-white"
            @submit.prevent="handleSubmit(submitForm)"
            @reset.prevent="reset"
          >
            <div class="field is-grouped is-grouped-multiline">
              <section class="control is-one-third-margin">
                <article class="field">
                  <ValidationProvider
                    name="NameSurname"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input-field
                      ref="fieldNameSurname"
                      :inputName="formBinds[0].id"
                      :labelName="formBinds[0].id"
                      :placeholder="formBinds[0].placeholder"
                      :error="!!errors[0]"
                      v-model="formData.name_surname"
                    />
                    <p class="help is-danger has-text-left">{{ errors[0] }}</p>
                  </ValidationProvider>
                </article>
              </section>

              <section class="control is-one-third-margin">
                <article class="field">
                  <ValidationProvider
                    name="Email"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <input-field
                      ref="fieldEmail"
                      :inputName="formBinds[1].id"
                      :labelName="formBinds[1].id"
                      :placeholder="formBinds[1].placeholder"
                      :error="!!errors[0]"
                      v-model="formData.email"
                    />
                    <p class="help is-danger has-text-left">{{ errors[0] }}</p>
                  </ValidationProvider>
                </article>
              </section>

              <section class="control is-one-third">
                <article class="field">
                  <ValidationProvider
                    name="Phone"
                    rules="required|phoneType|phoneLength"
                    v-slot="{ errors }"
                  >
                    <input-field
                      ref="fieldPhone"
                      :inputName="formBinds[2].id"
                      :labelName="formBinds[2].id"
                      :placeholder="formBinds[2].placeholder"
                      :error="!!errors[0]"
                      v-model="formData.phone"
                    />
                    <p class="help is-danger has-text-left">{{ errors[0] }}</p>
                  </ValidationProvider>
                </article>
              </section>
            </div>

            <section class="field">
              <ValidationProvider
                name="Text"
                rules="required"
                v-slot="{ errors }"
              >
                <textarea-field
                  ref="fieldText"
                  :textareaName="formBinds[3].id"
                  :labelName="formBinds[3].id"
                  :placeholder="formBinds[3].placeholder"
                  :error="!!errors[0]"
                  v-model="formData.text"
                />
                <p class="help is-danger has-text-left">{{ errors[0] }}</p>
              </ValidationProvider>
            </section>

            <section class="field has-text-left">
              <ValidationProvider
                name="GDPR"
                rules="requiredCheckbox"
                v-slot="{ errors }"
              >
                <label class="checkbox">
                  <input
                    ref="fieldGDPR"
                    type="checkbox"
                    v-model="formData.gdpr"
                  />
                  ZAPOZNAŁEM SIĘ Z
                  <router-link
                    to="/polityka-prywatnosci"
                    class="is-gdpr-accept"
                  >
                    INFORMACJĄ O ADMINISTRATORZE I PRZETWARZANIU DANYCH
                    OSOBOWYCH
                  </router-link>
                </label>
                <p class="help is-danger has-text-left">{{ errors[0] }}</p>
              </ValidationProvider>
            </section>

            <section class="field is-horizontal">
              <section class="field-body">
                <section class="field">
                  <article class="control">
                    <button
                      class="button is-link is-fullwidth"
                      :class="{
                        'is-success-button': mailSent,
                        'is-danger-button': errorMailSent,
                        'is-loading': isLoading,
                      }"
                      type="submit"
                    >
                      <span class="is-bold" v-if="!mailSent && !errorMailSent">
                        WYŚLIJ
                      </span>
                      <span
                        class="is-bold"
                        v-else-if="mailSent && !errorMailSent"
                      >
                        WYSŁANO
                      </span>
                      <span class="is-bold" v-else>BŁĄD</span>
                    </button>
                  </article>
                </section>
              </section>
            </section>
          </form>
        </ValidationObserver>
      </section>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';

extend('email', {
  ...email,
  message: 'Podaj poprawny adres e-mail.',
});

extend('required', {
  ...required,
  message: 'To pole jest wymagane.',
});

extend('requiredCheckbox', value => {
  if (value === true) {
    return true;
  }

  return 'To pole jest wymagane';
});

extend('phoneLength', value => {
  if (value.length === 9) {
    return true;
  }

  return 'Numer telefonu musi mieć 9 znaków.';
});

extend('phoneType', value => {
  const pattern = /^\d+$/;
  if (pattern.test(value)) {
    return true;
  }

  return 'Numer telefonu musi być liczbą.';
});

const initialFormState = () => ({
  name_surname: '',
  email: '',
  phone: '',
  text: '',
  gdpr: false,
});

export default {
  name: 'ContactForm',
  data() {
    return {
      title: 'MASZ PYTANIE? NAPISZ DO NAS!',
      subtitle: {
        firstLine: 'Pytanie nie może zostać bez odpowiedzi.',
        secondLine: 'Jeśli masz jakieś, napisz teraz!',
      },
      formData: initialFormState(),
      formBinds: [
        {
          id: 'name_surname',
          placeholder: 'IMIĘ I NAZWISKO',
        },
        {
          id: 'email',
          placeholder: 'E-MAIL',
        },

        {
          id: 'phone',
          placeholder: 'NUMER TELEFONU',
        },
        {
          id: 'text',
          placeholder: 'WPISZ TREŚĆ WIADOMOŚCI',
        },
      ],
      mailSent: false,
      errorMailSent: false,
      isLoading: false,
    };
  },
  components: {
    inputField: () => import('./functionalComponents/InputField'),
    textareaField: () => import('./functionalComponents/TextArea'),
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    resetForm() {
      this.formData = initialFormState();
      this.$refs.form.reset();
      this.$refs.fieldNameSurname.isActive = false;
      this.$refs.fieldEmail.isActive = false;
      this.$refs.fieldPhone.isActive = false;
      this.$refs.fieldText.isActive = false;
      this.$refs.fieldGDPR.isActive = false;
    },

    submitFormStatus(status) {
      const result = status === 'success';

      this.isLoading = false;

      if (result) this.mailSent = true;
      else this.errorMailSent = true;

      setTimeout(() => {
        if (result) this.mailSent = false;
        else this.errorMailSent = false;

        this.resetForm();
      }, 3000);
    },

    async submitForm() {
      try {
        this.isLoading = true;

        const token = await this.$recaptcha('login');
        const mail = await axios.post('/api/mail', this.formData, {
          headers: {
            recaptcha: token,
          },
        });

        this.submitFormStatus(
          mail.data.statusCode === 201 ? 'success' : 'error',
        );
      } catch (error) {
        this.submitFormStatus('error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.has-no-padding {
  padding: 0;
}
.has-text-left {
  text-align: left;
}
.height-625 {
  width: 100%;
  height: auto;
  bottom: 0;
  max-height: 625px;
}

@media only screen and (max-width: 768px) {
  .is-one-third-margin {
    width: 100%;
  }
  .is-one-third {
    width: 100%;
  }
}

@media only screen and (min-width: 766px) {
  .is-one-third-margin {
    width: calc(33.3333333% - 0.75rem);
  }
  .is-one-third {
    width: 33.3333333%;
  }
}
.is-link {
  background-color: $primary;
  border-radius: 0;
  font-size: 12px;
  padding: 1.5rem;

  &:hover {
    background-color: darken($primary, 5);
  }
}

.is-bold {
  font-weight: bold;
}

.is-success-button {
  background-color: hsl(153, 53%, 53%);
  border-radius: 0;
  font-size: 12px;
  padding: 1.5rem;

  &:hover {
    background-color: darken(hsl(153, 53%, 53%), 5);
  }
}

.is-danger-button {
  background-color: hsl(348, 86%, 61%);
  border-radius: 0;
  font-size: 12px;
  padding: 1.5rem;

  &:hover {
    background-color: darken(hsl(348, 86%, 61%), 5);
  }
}

.checkbox {
  color: $grey-light;
  font-size: 12px;

  &:hover {
    color: $grey-light;
  }
}

.is-gdpr-accept {
  color: $grey-light;
  font-size: 12px;

  &:hover {
    color: $primary;
  }
}
</style>
